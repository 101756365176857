<template>
	<div
		:class="{[$s.row]:1,[$s.friends]:friends}"
		v-if="friends"
	>
		<div>{{ transaction.login }}</div>
		<div>{{ transaction.data }}</div>
	</div>
	<div
		:class="$s.row"
		v-else
	>
		<div>{{ transaction.id }}</div>
		<div>{{ transaction.date }}</div>
		<div :class="{[$s.sum]:1, [$s.plus]:transaction.sum>0}">{{ transaction.sum }}</div>
		<div :class="[$s.info, isPay ? $s.pay : '']">{{ transaction.description }}</div>
	</div>
</template>
<script>
export default {
	props: ['transaction', 'friends'],
	computed:
		{
			isPay()
			{
				return this.transaction.description.indexOf('Оплата') > -1;
			}
		}
}
</script>
<style
	lang="scss"
	module="$s"
>
.row
{
	&.friends
	{
		grid-template-columns: 100px 1fr;
	}

	display: grid;
	background: $greyBackground;
	height: fit-content;
	border-radius: 8px;
	grid-template-columns: 70px 170px 70px 1fr;
	padding: 10px;

	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;

	justify-items: center;

	div
	{
		display: grid;
		place-content: center;
	}

	.info
	{
		justify-content: inherit;
	}

	.pay
	{
		color: $red;
	}

	.sum
	{
		color: $red;

		&.plus
		{
			color: $green;
		}

	}
}
</style>