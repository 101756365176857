<template>
	<div
		:class="$s.paginator"
		v-if="totalPages > 1"
	>
		<div
			@click="currentPage = Math.max(currentPage-1,1 )"
			:class="[$s.btn, $s.arrow]"
		>&lt;
		</div>
		<div :class="$s.numberRow">
			<div
				v-for="page in showedCount"
				:key="page"
				:class="[$s.btn, page===currentPage ? $s.current : '']"
				@click="currentPage = page"
			>{{ page }}
			</div>
		</div>
		<div
			@click="currentPage = Math.min(currentPage+1,totalPages )"
			:class="[$s.btn, $s.arrow]"
		>&gt;
		</div>
	</div>
</template>

<script>
export default {
	props: ['value', 'totalPages'],
	data()
	{
		return {
			limit: 6,
		}
	},
	computed: {
		currentPage: {
			get()
			{
				return this.value;
			},
			set(v)
			{
				this.$emit('changePage', v)
			}
		},
		showedCount()
		{
			let arr = []
			let count = this.limit - 1;

			if (window.innerWidth < 590) count -= 1;
			if (window.innerWidth < 450) count -= 1;
			if (window.innerWidth < 400) count -= 1;
			if (window.innerWidth < 350) count -= 1;

			let start = Math.min(Math.max(this.value - 1, 1), Math.max(this.totalPages - count, 1));
			for (let i = start; i <= Math.min(this.limit + this.value - 1, this.totalPages); i++)
				arr.push(i)
			return arr;
		}
	}
}
</script>

<style
	module="$s"
	lang="scss"
>
.paginator
{
	display: grid;
	grid-auto-flow: column;
	justify-content: center;
	align-items: center;
	padding: 8px;
}

.btn
{
	width: 48px;
	height: 48px;
	border-radius: 48px;
	line-height: 48px;
	text-align: center;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 14px rgba(0, 0, 0, 0.08);
	cursor: pointer;

	font-weight: 600;
	font-size: 16px;
	color: $textColor;
	user-select: none;

	&:hover
	{
		background-color: $textColor;
		color: white;
	}

	@media (max-width: 1024px)
	{
		&:hover
		{background: none}
		&:active
		{background: $textColor}
	}

	&.current
	{
		background-color: $green;
		color: white;
	}

	&.arrow
	{
		color: $green;
	}
}

.numberRow
{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(48px, 1fr));
	height: 70px;
	overflow: hidden;
	max-width: 340px;
	gap: 16px 5px;
	padding: 0 10px;
	justify-items: center;

	.btn
	{
		margin-top: 12px;
	}
}
</style>
