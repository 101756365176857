<template>
	<div
		:class="$s.main"
		v-if="transactions.length > 0"
	>
		<div :class="$s.table">
			<div :class="{[$s.head]:1, [$s.friends]:friends}">
				<div
					v-for="el in sortList"
					:key="el.code"
					:class="{
                            [$s.sort]:el.code===sort,
                            [$s.desc]:(el.code===sort) && sortDesc,
                        }"
					@click="toggleSort(el.code)"
				>{{ el.name }}
				</div>
			</div>
			<Row
				v-for="(transaction, n) in transactions"
				:transaction="transaction"
				:key="n"
				:friends="friends"
			/>
		</div>
		<Paginator
			v-if="totalPages > 1"
			v-model="currentPage"
			:totalPages="totalPages"
			@changePage="changePage($event)"
		/>
	</div>
	<div
		v-else
		:class="$s.blank"
	>
		<div>{{ msg[0] }}</div>
		<div>{{ msg[1] }}</div>
	</div>
</template>

<script>
import Row from '@/components/block/Personal/TransactionTableRow.vue'
import Paginator from '@/components/block/Personal/Paginator.vue'
import {DateTime} from 'luxon'
import {mapState} from 'vuex'

export default {
	props: ['transactions', 'totalPages', 'friends'],
	components: {Row, Paginator},
	data()
	{
		return {
			sort: 'date',
			sortDesc: true,
			sortedList: this.transactions,
			sortList: [
				{name: 'Заказ', code: 'id'},
				{name: 'Дата', code: 'date'},
				{name: 'Сумма', code: 'sum'},
				{name: 'Описание', code: 'info'},
			],

			currentPage: 1,
		}
	},
	computed: {
		...mapState('personal', ['msg']),
	},
	methods: {
		toggleSort(code)
		{
			if (code === this.sort)
			{
				this.sortDesc = !this.sortDesc;
			} else
			{
				this.sort = code;
				this.sortDesc = true
			}
			this.currentPage = 1;
			this.emitUpdate();
		},
		changePage(val)
		{
			this.currentPage = val;
			this.emitUpdate();
		},
		emitUpdate()
		{
			let desc = this.sortDesc
			let sort = this.sort
			let page = this.currentPage
			this.$emit('updateList', {page, sort, desc});
		}
	},
	mounted()
	{
		if (this.friends)
		{
			this.sortList = [
				{name: 'Логин', code: 'login'},
				{name: 'Дата ввода промокода', code: 'data'},
			]
		}
	}
}
</script>

<style
	module="$s"
	lang="scss"
>
.main
{
	padding-bottom: 10px;
}

.table
{
	display: grid;
	gap: 8px;
	margin-bottom: 32px;
	overflow: auto;

	&::-webkit-scrollbar
	{
		width: 0;
		height: 0;
	}

	.head
	{
		&.friends
		{
			grid-template-columns: 100px 1fr;
		}

		display: grid;
		grid-template-columns: 70px 170px 70px 1fr;
		text-align: center;
		font-weight: 500;
		font-size: 14px;
		cursor: pointer;

		.sort
		{
			position: relative;

			&::after
			{
				content: url('~@/assets/img/Personal/sortTriangle.svg');
				position: absolute;
				top: 0;
				width: 12px;
				height: 12px;
			}

			&.desc::after
			{
				transform: rotate(180deg);
				top: auto;
				bottom: -3px;
			}
		}
	}
}

.blank
{
	text-align: center;
	font-family: $mainFont;
	margin: 50px 0;

	div:nth-child(1)
	{
		color: $textColor;
		font-family: $mainFontBold;
		font-size: 32px;
		line-height: 40px;
		margin-bottom: 16px;
	}

	div:nth-child(2)
	{
		color: $greyLightText;
		font-size: 16px;
		line-height: 40px;
		margin-bottom: 24px;
	}
}
</style>